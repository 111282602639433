.search-block {
  .block-title {
    margin-bottom: 70px;

  }

  .carousel-indicators {
    margin: 0;
    justify-content: left;

    li {
      background: #e1e1e1;
      width: 12px;
      height: 12px;
      border-radius: 30px;
      border: none;

      &.active {
        background: $color-red;
      }
    }
  }

  .carousel-inner {
    margin: 30px 0 0;
    @media #{$tablet-min} {
      margin: 0;
    }
    .row {
      .carousel-data {
        h3 {
          color: $color-blue;
          text-transform: uppercase;
          font-size: 24px;
          padding: 0 0 10px;
          margin-left: 15px;

        }

        ul {
          li {
            font-size: 15px;
            padding-bottom: 20px;
            position: relative;
            padding-left: 20px;
            margin-left: 15px;

            span {
              color: $color-blue;
              text-transform: uppercase;
              font-size: 18px;
              font-weight: 500;
            }

            &:before {
              content: "";
              background: $color-blue;
              width: 15px;
              height: 15px;
              position: absolute;
              left: 0;
              top: 5px;
              border-radius: 50px;
            }
          }
        }
      }
    }
  }
}

