

/*
 * Heading-styles
 */

@mixin heading-style-1 {
  font-size: 48px;
  @media #{$tablet}{
    font-size: 32px;
  }
  @media #{$iphone} {
    font-size: 24px !important;
  }
}

@mixin heading-style-2 {
  font-size: 24px;
  @media #{$mobile} {
    font-size: 18px;
  }
}

@mixin heading-style-3 {
  font-size: 20px;
  @media #{$mobile}{
    font-size: 16px;
  }
}



/*
 * Paragraph Text-styles
 */

@mixin text-style-1{
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  @media #{$mobile}{
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin text-style-2{
  font-size: 16px;
  line-height: 24px;
  font-weight:normal;
  @media #{$mobile}{
    font-size: 15px;
    line-height: 22px;
  }
}

@mixin text-style-3{
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;

}