.coming-soon {
  margin: 70px 0;

  h2 {
    font-size: 20px !important;
    padding: 0 0 20px 0;
    font-weight: 600;
    color: $color-blue;
    margin: 0;
    @media #{$tablet-min} {
      font-size: 36px !important;
    }
  }

  .col-md-3 {
    .button-link {
      position: relative;
      img {
        width: 100%;
      }
      .text-2{
        position: absolute;
        top: 0;
        margin: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        //background: rgba(0, 0, 0, .3);
        border-radius: 10px;
        color: $color-blue;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        padding: 12px 20px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
      &.sap, &.microsoft{
        &:hover{
          .text-2{
            display: block;
          }
          img{
            opacity: .3;
            filter: grayscale(1);
          }
        }
      }

    }

  }
}