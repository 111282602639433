.faq-global {
  .faq-fields {
    margin-bottom: 20px;
    .accordion {
      background-color: $gray;
      color: $color-blue;
      cursor: pointer;
      padding: 12px 50px 12px 18px;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      transition: 0.4s;
      position: relative;
      @media #{$tablet-min} {
        font-size: 20px;
      }

      &.active {
        background-color: $color-blue;
        color: $color-white;
        &:after{
          background: url(../svgs/down-arrow-white.svg);
          transform: rotate(180deg);
        }
      }
      &:after{
        content: "";
        background: url(../svgs/down-arrow.svg);
        width: 18px;
        position: absolute;
        right: 20px;
        height: 18px;
        top: 15px;

      }
    }

    .panel {
      padding: 12px 18px;
      display: none;
      background-color: $light-gray;
      overflow: hidden;
      margin-bottom: 30px;

      ul{
        padding: 0 0 0 10px;
        margin: 0;
        li{
          list-style-type: decimal !important;
          padding: 0 0 10px;
        }
      }
    }
  }
}