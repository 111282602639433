
a {
  text-decoration: none !important;
}

ul, li {
  list-style: none !important;
  padding: 0;
}

input:focus, select:focus {
  outline: none
}

button, button:focus, button:active{
  outline: none;
}

body {
  font-family: $font-family;
}

//global-block-heading

.block-title {
  margin-bottom: 30px;

  h1 {
    color: $color-blue;
    font-weight: 700;
    display: inline-block;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 50%;
      height: 8px;
      background: $color-red;
      left: 0;
      bottom: -20px;

      @media #{$mobile} {
        height: 5px;
      }
    }
  }

  &.center {
    text-align: center;

    h1 {
      &:after {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}

//section-gap-global

.m-top {
  margin: 70px 0 0;

  @media #{$tablet} {
    margin: 50px 0 0;
  }
  @media #{$iphone} {
    margin: 40px 0 0;
  }
}

.m-top-inner {
  margin: 30px 0 0;

  @media #{$tablet} {
    margin: 20px 0 0;
  }
  @media #{$iphone} {
    margin: 15px 0 0;
  }
}


//hidden
.desktop-only{
  display: none;
  @media #{$tablet-min} {
    display: block;
  }
}
.mobile-only{
  display: none;
  @media #{$mobile} {
    display: block;
  }
}


//
.hero-banner{
  h3{

  }
}

.social-sharing{
  position: fixed;
  right: 0;
  top: 30%;
  z-index: 9;
  li{
    img{
      width: 40px;
    }
  }
}

.help-block{
  display: none;
  font-size: 13px;
}

//
.newsletter-popup{
  .form-group {
    label {
      font-size: 14px;
    }

    input {
      color: $color-blue !important;
      border-color: $color-border !important;
      margin: 0 !important;

      &::placeholder {
        color: $color-border !important;
      }
    }

    span {
      display: none;
      font-size: 14px;
      color: #f00;
    }
  }
  .close{
    background: none !important;
    span{
      color: $color-blue;
      font-size: 24px;
    }
  }
  .modal-footer{
    button{
      border: 1px solid $color-blue !important;
      font-size: 14px !important;
      font-weight: 500;
      color: $color-blue !important;
      background: none !important;
      padding: 10px 30px !important;

      &:hover{
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
  }
}









