.how-works {
  margin-bottom: 50px;

  .block-title {
    text-align: center;
    @media #{$tablet-min} {
      text-align: left;
    }

    h1 {
      color: $color-white !important;
    }
  }

  .left-block {
    background: url("../images/background-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 100px 20px;
    @media #{$tablet-min} {
      padding: 100px 20px 100px 60px;
    }

    h2 {
      color: $color-white;
      text-transform: uppercase;
      padding: 20px 0 0;
      margin: 0;
    }

    p {
      color: $color-white;
    }

      .block-title {
        .heading-1 {
          &:after {
            @media #{$mobile} {
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
      }
    }
  }

  .right-block {
    margin: 30px 0 0;
    @media #{$tablet-min} {
      margin: 0;
    }

    .row {
      .col-md-6 {
        background: $light-gray;
        padding: 40px 20px;
        text-align: center;
        margin: 0 auto 30px;
        @media #{$tablet-min} {
          margin-bottom: 0;
        }

        &:first-child, &:last-child {
          background: $color-red;
          color: $color-white;
        }

        h3 {
          text-transform: uppercase;
          padding: 20px 0;
          font-weight: 600;
          margin: 0;

        }


      }
    }

  }
}