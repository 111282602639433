.why-us.m-top {
  text-align: center;

  .block-title {
    margin-bottom: 30px;
    @media #{$tablet-min} {
      margin-bottom: 50px;
    }
    p{
      margin: 30px 0 0;
    }
  }

  .row {
    .col-md-6 {
      margin: 0 0 30px;
      @media #{$tablet-min} {
        margin: 0;
      }
      .tass-block {
        box-shadow: 0px 0px 5px 0px rgba(102, 102, 102, .2);
        height: 100%;
        padding: 20px 15px;
        @media #{$tablet-min} {
          padding: 35px 18px;
        }

        .text-1 {
          text-transform: uppercase;
          padding: 15px 0;
          margin: 0;
          color: $color-red;
          font-weight: 500;
        }
        img{
          width: 60px;
        }
      }
    }
  }
}