/*

 * Heading-fonts included from _mixin-typography

 */


.heading-1, h1 {
  @include heading-style-1;
}

.heading-2, h2 {
  @include heading-style-2;
}

.heading-3, h3 {
  @include heading-style-3;
}



/*

 * Paragraph Fonts included from _mixin-typography

 */


.text-1 {
  @include text-style-1;
}

.text-2 {
  @include text-style-2;
}

.text-3 {
  @include text-style-3;
}
