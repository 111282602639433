.about-us {
  .heading-2 {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .field-image-block {
    .row {
      @media #{$tablet-min} {
        margin-bottom: 20px;
      }

      .image-field{
        img{
          width: 100%;
        }
      }

      .content-field {
        margin: 30px 0 0;
        @media #{$desktop-min} {
          margin: 0;
        }
        p {

        }
      }

    }
  }
}