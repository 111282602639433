@import "mixin-typography";


@mixin button-circle {
  background: $color-red;
  color: $color-white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 100px;
  padding: 10px 40px;
  text-transform: uppercase;
  -webkit-transition: .3s;
  transition: .3s;
  border: 1px solid $color-red;

  &:hover{
    background: $color-white;
    color: $color-red;
    border: 1px solid $color-red;
  }
}