header {
  position: absolute;
  top: 0;
  width: 100%;

  nav {
    .navbar-brand {
      max-width: 210px;

      img {
        width: 115px;
        @media #{$tablet-min} {
          width: 125px;
        }
      }
    }

    .navbar-collapse {
      justify-content: flex-end;

      .navbar-nav {
        li {
          padding: 0 10px;

          a {
            color: $color-white;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 15px;

            &:hover {
              color: $color-red;
            }

            &.active {
              color: $color-red;
            }

            &.login-button {
              border: 1px solid $color-red;
              border-radius: 100px;
              padding-left: 20px;
              padding-right: 20px;
              background: $color-red;
              transition: .3s;
              display: inline-block;
              margin: 10px 0 15px;

              @media #{$tablet-min} {
                margin: 0;
              }
              &:hover{
                background: $color-white;
                border-color: $color-white;
              }
            }
          }
        }
      }
    }

    .navbar-toggler {
      &:focus, &:hover {
        outline: none;
      }

      img {
        filter: invert(1);

      }
    }
  }

  .navbar-collapse.collapse.show {
    background: $color-blue;
    z-index: 9;
  }
}
