.gallery {
  text-align: center;

  h2 {
    text-transform: uppercase;
    margin: 30px 0;
    font-weight: 600;
  }

  .row {
    div {
      margin: 0 0 30px;

      img {
        width: 100%;
        @media #{$tablet-min} {
          height: 100%;
        }
      }
    }
  }
}