/*
 * Devices width
 */
$custom: '(max-width: 420px)';
$mobile: '(max-width: 767px)';
$iphone: '(max-width: 567px)';
$iphone-min: '(min-width: 568px)';
$tablet-min: '(min-width: 768px)';
$tablet: "(max-width: 991px)";
$desktop-min: "(min-width: 992px)";
$desktop-i: "(min-width: 1200px)";
$desktop: "(max-width: 1300px)";
$desktop-wide: "(min-width: 1400px)";



/*
 * colors
 */
//colors
$color-red:#f76a61;
$color-blue: #22305e;
$color-dark-gray: #39424e;
$color-white: #ffffff;
$text-color:#333;
$gray: #eeeeee;
$light-gray:#f9f9f9;
$color-background: #383838;
$color-light-gray: #eeeeee;
$color-gray-shadow: #bcc0c4;
$color-heading: #333333;
$color-border:#cccccc;





/*
 * Font-family
 */


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

$font-family: 'Montserrat', sans-serif;
$font-awesome: 'fontawesome';

