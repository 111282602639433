footer {
  background: $color-blue;
  padding: 30px 0 0;
  @media #{$tablet-min} {
    padding: 60px 0 0;
  }
  .row {
    .col-md-6, .col-sm-6{
      margin: 20px 0 0;
      @media #{$tablet-min} {
        margin: 0;
      }
    }
    .footer-nav {
      li {
        padding-bottom: 20px;

        a {
          color: $color-white;
          font-size: 15px;

        }
      }
    }



    .newsletter {
      h2 {
        text-transform: uppercase;
        font-weight: 700;
        color: $color-white;
      }

      input {
        width: 100%;
        border-radius: 100px;
        background: none;
        color: $color-white;
        border: 1px solid $color-white;
        padding: 12px 20px;
        margin: 20px 0;
        font-size: 15px;
      }

      button {
        background: $color-red;
        color: $color-white;
        font-size: 18px;
        font-weight: 600;
        border: none;
        border-radius: 100px;
        padding: 12px 20px;
        text-transform: uppercase;
        transition: .3s;

        &:hover {
          background: $color-white;
          color: $color-red;
        }

      }
    }
  }
  .copyrights {
    border-top: 1px solid $color-white;
    margin: 40px 0 0;
    text-align: center;
    padding: 20px 0;

    p {
      color: $color-white;
      font-size: 12px;
      font-weight: 300;
      margin: 0;
      text-transform: uppercase;
    }
  }
}

