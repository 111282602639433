//about-us
.about-us.page {
  .content-field {
    h2 {
      text-transform: uppercase;
      font-weight: 600;
      color: $color-blue;
    }

    .text-1 {
      font-weight: 600;
      color: $color-red;
    }
  }
}

//why-taas


.hero-banner.banner-why-tass {
  h1 {
    text-transform: none;
    font-size: 44px !important;

  }

  h3 {

  }
}

.why-tass {
  .taas-list {
    margin-bottom: 0;

    li {
      position: relative;
      padding: 0 0 10px 30px;

      &:before {
        content: "";
        background: url("../images/taas-list.png");
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 5px;
        left: 0;

      }
      span{
        color: $color-red;
      }
    }
  }

  .inner-block-2 {
    h2 {
      text-transform: uppercase;
      font-size: 20px;
      color: $color-blue;
      font-weight: 600;
      @media #{$tablet-min} {
        font-size: 30px;
      }
    }

    img {
      margin: 0 0 20px;
      @media #{$tablet-min} {
        margin: 0;
      }
    }

    .text-gap {
      @media #{$tablet-min}{
        margin-top: 15px;
      }
    }

    &.taas-one {
      p{
        @media #{$tablet-min} {
          margin-bottom: 0;
        }
      }

    }

    &.taas-two {
      margin-bottom: 30px;
      @media #{$tablet-min} {
        margin-bottom: 0;
      }

    }

    &.taas-three {
      p{
        span{
          color: $color-red;
        }
      }
      ul{
        li{
          padding-bottom: 10px;
          a{
            font-weight: 500;
          }
        }
      }

    }
  }
}

//faq
.faq.page {
  h2 {
    color: $color-blue;
    font-weight: 600;
  }
}


//privacy-policy
.privacy-policies {
  .policies-inner {
    padding: 20px 0 0;

    h2 {
      font-weight: 600;
    }

    .policy-list {
      padding: 10px 0 0;

      li {
        position: relative;
        padding: 0 0 12px 25px;
        font-weight: 500;

        &:before {
          content: "";
          background: url("../images/policy-list.png");
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 3px;

        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    p {
      a {
        color: $color-red;

      }
    }
  }

  .faq-global {
    .faq-fields {
      .panel {
        h3 {
          color: $color-red;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}


//contact us
.contact-us.page {
  .left-block {
    margin-bottom: 50px;
    @media #{$tablet-min} {
      margin-bottom: 0;
    }
    h2 {
      color: $color-blue;
    }

    form {
      margin: 15px 0 0;

      .form-group {
        margin: 0 0 20px 0;

        input {
          width: 100%;
          border: none;
          padding: 15px 10px;
          box-shadow: 0px 0px 5px 0px $color-border;
          border-radius: 5px;

          &::placeholder {
            color: $color-border;
          }
        }
      }

      .text-1 {
        margin: 0;
        color: $color-blue;
        font-weight: 500;
      }

      textarea {
        width: 100%;
        border: none;
        padding: 15px 10px;
        box-shadow: 0px 0px 5px 0px $color-border;
        border-radius: 5px;
        height: 200px;

        &:focus {
          outline: none;
        }
      }

      .button-type-circle {
        display: inline-block;
        margin: 20px 0 0;
      }
    }
  }

  .right-block {
    h2 {
      color: $color-red;
    }

    ul {
      li {
        padding: 0 0 10px 40px;
        position: relative;

        a {
          font-weight: 500;
          color: $color-dark-gray;

          span {
            position: absolute;
            left: 0;
          }
        }
      }
    }

    p {
      padding: 0 0 10px 30px;
      position: relative;
      font-weight: 500;
      color: $color-dark-gray;

      span {
        position: absolute;
        left: 0;
      }
    }
  }
}


//how-it-works
.how-it-works-page {
  .how-it-works-inner{
    margin-bottom: 40px;
    .works-block{
      p{
        font-size: 15px;
        color: $color-dark-gray;
        @media #{$tablet-min} {
          font-size: 18px;
        }
        strong{
          color: $color-red;
        }
      }
    }
  }

  .image-field {
    width: 100%;
  }

  .video-field {
    video {
      width: 100%;
      box-shadow: 0px 0px 5px 0px #ccc;
    }
  }
}