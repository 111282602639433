.hero-banner{
  background: url("../images/main-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25% 0 30%;
  background-position: bottom center;
  @media #{$tablet-min} {
    padding: 13% 0 25%;
  }

  @media #{$desktop-min} {
    padding-bottom: 18%;

  }
  @media (min-width: 1120px) {
    background-position: bottom left;
  }

  .row {
    align-items: center;
    .banner-caption {
      margin: 20px 0 0;
      @media #{$tablet-min} {
        margin: 0;
      }

      h1 {
        color: $color-white;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 60px 0 0;
        @media #{$iphone-min} {
          margin: 40px 0 0;
        }
        @media #{$tablet-min} {
          font-size: 40px;
        }
        @media #{$desktop-min} {
          margin: 0;
          font-size: 60px;
        }

        span {
          color: $color-red;
        }
        b{
          font-weight: 700;
          text-transform: capitalize;
        }
      }

      h3 {
        font-size: 15px;
        margin: 10px 0;
        line-height: 26px;
        color: $color-white;
        @media #{$tablet-min} {
          font-size: 16px;
          margin: 20px 0;
        }
      }

      ul {
        margin: 10px 0 0;
        @media #{$tablet-min} {
          margin: 20px 0 0;
        }

        li {
          float: left;
          margin: 0 20px 0 0;

          a {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: 100px;
            padding: 10px 15px;
            color: $color-white;
            background: $color-red;
            transition: .3s;
            display: block;
            margin: 0 0 10px;

            @media #{$tablet-min} {
              padding: 12px 30px;
            }

            &:hover {
              background: $color-white;
              color: $color-red;
            }
          }
        }
      }
    }
    img{
      width: 90%;
    }
  }
  .three-buttons{
    .col-4{
      position: relative;
      .text-2{
        position: absolute;
        top: 0;
        margin: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        color: $color-blue;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        padding: 12px 20px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
      &.t-sap, &.t-microsoft{
        &:hover{
          .text-2{
            display: block;
          }
          img{
            opacity: .9;
            filter: grayscale(1);
          }
        }

      }

    }
  }
}